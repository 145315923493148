function f_resa_options(){
    $("#options input,  #options select").change(function() {
        f_calcul_devis();

        priceToggleActive($(this));
    });

    $("#code_promo_validation").click(function() {
        $('#error-message').html('');
        f_calcul_devis();
    });

    function f_calcul_devis() {
        $.ajax({
            method: "POST",
            url: $("#options").data('ajax-url'),
            data: $("#devis").serialize(),
            success: function(data) {
                $("#total").text(data.total);
                $("#promotion").html(data.promotion);
            },
            error: function (request) {
                if(request.responseJSON.message) {
                    const html = '<div class="alert alert-danger" role="alert">' + request.responseJSON.message + '</div>';
                    $('#error-message').html(html);
                }
            }
        });
    }

    $("#options input:first").trigger('change');

    function priceToggleActive(element) {
        var quantite = 0;
        if (element.prop("tagName") === 'SELECT' && element.val() >= 1) {
            quantite = element.val();
        } else if (element.prop("tagName") === 'INPUT' && element.is(":checked")) {
            quantite = 1;
        }
        var $option = element.parents('.item');
        var prix = $option.find('.options-price').data('tarif');
        if (quantite !== 0) {
            $option.addClass('active');
            $option.find('.sprite').addClass('icon-active');
            prix =  prix * quantite;
        } else {
            $option.removeClass('active');
            $option.find('.sprite').removeClass('icon-active');
        }
        var prixString = prix == 0 ? 'Gratuit' : prix.toLocaleString('fr', {minimumFractionDigits: 2, maximumFractionDigits: 2} + ' €');
        $option.find('.options-price').text(prixString);
    }

    $("#options input,  #options select").each(function () {
        priceToggleActive($(this));
    });
}

f_resa_options();


function f_Carrosserie(){
    $("#carrosserie").on('change', function() {
        if ($(this).val() !== '') {
            const selectedCarrosserie = $(this).val();
            $('.resa-car').hide();
            $('.resa-car').each(function() {
                const carrosseries = $(this).attr('data-carrosserie').split(',');
                if( carrosseries.includes(selectedCarrosserie) ) {
                    $(this).show();
                }
            });
        } else {
            $('.resa-car').show();
        }

        $('#search-count').text($('.resa-step-1:visible:not(.unavailable)').length);
    });
    $("#carrosserie").trigger('change');
}
f_Carrosserie();


function f_inputEffect(){
    $('.form-control-bis').each(function(i) {
        isEmpty = $(this).val();
        if(!isEmpty==''){
            $(this).addClass('active');
        }

        $(this).on('blur',function(){
            if( $(this).val() === '' ) {
                $(this).removeClass('active');
            }else{
                $(this).addClass('active');

            }
        });
    });
}

f_inputEffect();

// Component :: FAQ Toggle
function f_faqToggle(){
    $('.item-faq .content').hide();
    $('.item-faq .button').on('click',function(){
        var isOpen = $(this).parents().eq(1).hasClass('open');
        if(isOpen){
            $('.item-faq .content').hide();
            $('.item-faq').removeClass('open');
        }else{
            $('.item-faq .content').hide();
            $('.item-faq').removeClass('open');
            $(this).parent().next().toggle();
            $(this).parents().eq(1).toggleClass('open');
        }

        return false;
    });
}
f_faqToggle();

// Component :: Calendar Toggle
function f_calendarToogle() {
    $('.resa-search-btn').on('click',function() {
        $('#datepicker-start').focus();
    });
}
f_calendarToogle();
