// Navigation mobile
function nav_mobile(){
    $('#nav-mobile-trigger').on('click',function(){ 
        $('body').toggleClass('menu-open');
        $('#nav-main, #nav-top').toggle();
    });
}

// Navigation size 
function nav_form_resa_fixed(){
    var headerH = $('#resa-header').height();
    var formH = $('#resa-fixed').innerHeight();
    var position = $(window).scrollTop();
    if (position > headerH) {
        $('body').addClass('form-resa-fixed');
        $('body').css('paddingTop',formH);
    }else {
        $('body').removeClass('form-resa-fixed');
         $('body').css('paddingTop',0);
    }
    $(window).scroll(function() {
        if ($(this).scrollTop() > headerH) {
        $('body').addClass('form-resa-fixed');
        $('body').css('paddingTop',formH);
        }else {
            $('body').removeClass('form-resa-fixed');
            $('body').css('paddingTop',0);
        }
    });

}

function nav_form_resa_toggle(){
    $('#resa-header-summary .link-edition.date').on('click',function(){
        $('#resa-search').toggleClass('hide');
        return false;
    }); 
}

// Navigation sub menu 
function nav_sub_menu(){
    var viewport = $(window).width();
    if(viewport<576){
        $('#nav-main li[data-navsub] > a').on('click',function(event){
            $(this).next().toggle();
            return false;
        });
    }else{
        $('#nav-main li[data-navsub] > a').on('click',function(event){
            var navSub = $(this).parent();
            var isFocus = navSub.hasClass('focus');
            $('#nav-main li[data-navsub]').removeClass('focus');
            if(!isFocus){
                navSub.addClass('focus');
            }
            event.stopPropagation();
            return false;
        });
        $('html').click(function() {
            $('#nav-main li[data-navsub]').removeClass('focus');
        });
    }
}

// Scroll top button
function scroll_top(){
    $('#btn-back-top').fadeOut(0);
    $(window).scroll(function() {
        if ($(this).scrollTop() > 220) {
            $('#btn-back-top').fadeIn(300);
        }else {
            $('#btn-back-top').fadeOut(300);
        }
    });
    $('#btn-back-top').on('click',function(){
        $('html, body').animate({scrollTop:0}, '500');
        return false;
    });
}

// Initialisation
nav_mobile();
nav_sub_menu();
nav_form_resa_fixed();
nav_form_resa_toggle();
scroll_top();

$(window).on('resize',function(){  
    nav_form_resa_fixed();
}); 

