// JS dependencies
var bootstrapselect = require('bootstrap-select');

// CSS dependency
import '/../node_modules/bootstrap-select/dist/css/bootstrap-select.css';

// Initialisation
$('.selectpicker').selectpicker({
	container: 'body'
});

$('.selectpicker').on('changed.bs.select', function (e) { 
	$(this).parent().addClass('changed'); 
}); 

