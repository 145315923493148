// JS dependency
var slick = require('slick-carousel');

// CSS dependency
import '/../node_modules/slick-carousel/slick/slick.css';

// Slick Home 
$('#slick-home').slick({
    slidesToShow : 1,
    slidesToScroll : 1,
    dots: false,
    fade: true,
    arrows : false,
    autoplay: true,
    adaptiveHeight: true,
    cssEase: 'ease-in-out',
    speed: 400,
    autoplaySpeed: 4000,
    responsive : [
        {
            breakpoint : 1200,
            settings:{
            }
        },
        {
            breakpoint : 991,
            settings:{
            }
        },
        {
            breakpoint : 576,
            settings:{
                autoplay: false
            }
        },
     ]      
});

// Slick Home animations


//Slick Inner
$('.slick-car').slick({
    slidesToShow : 5,
    slidesToScroll : 2,
    dots: false,
    fade: false,
    arrows : true,
    autoplay: false,
    adaptiveHeight: true,
    cssEase: 'ease-in-out',
    speed: 400,
    autoplaySpeed: 6000,
    appendArrows: '#slick-car-arrows',
    prevArrow : '<button class="arrow-prev"><span class="sprite prev-grey"></span></button>',
    nextArrow : '<button class="arrow-next"><span class="sprite next-grey"></span></button>',
    responsive : [
        {
            breakpoint : 1200,
            settings:{
                slidesToShow : 4
            }
        },
        {
            breakpoint : 991,
            settings:{
                slidesToShow : 3
            }
        },
        {
            breakpoint : 768,
            settings:{
                slidesToShow : 2
            }
        },
        {
            breakpoint : 576,
            settings:{
                slidesToShow : 1,
                slidesToScroll : 1
            }
        }
     ]
});


           
