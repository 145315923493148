// Configuration
WebFontConfig = {
    google: { families: ['Noto+Sans:400,400i,700,700i','Satisfy','Material+Icons'] }
};

// Initialisation
(function() {
    var wf = document.createElement('script');
    wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
    wf.type = 'text/javascript';
    wf.async = 'true';
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(wf, s);
})(); 
