// JS dependency
var stickykit = require('sticky-kit/dist/sticky-kit.js');

// Initialisation   
function stickykit_init(){
  var viewport = $(window).width();
    if(viewport > 576){
        $("#sticky-wp").stick_in_parent();
    }else{
        $("#sticky-wp").trigger("sticky_kit:detach");
    }
}

stickykit_init();

$(window).on('resize',function(){ 
	stickykit_init();
});
