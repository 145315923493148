window.addEventListener('DOMContentLoaded', function() {
    // Evenement RGPD
    window.initializeMaps = function () {

        var markerCluster = require('js-marker-clusterer');

        var map = new google.maps.Map(document.getElementById('gmap-cluster'), {
            zoom: 12,
            scrollwheel: false,
            panControl: false,
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            center: {
                lat: 14.62,
                lng: -61
            }

        });

        var infoWin = new google.maps.InfoWindow();
        var markers = locations.map(function(location, i) {
            var marker = new google.maps.Marker({
                position: location,
                icon: '/theme/images/icons/gmap-marker.png'
            });
            google.maps.event.addListener(marker, 'click', function(evt) {
                infoWin.setContent(location.info);
                infoWin.open(map, marker);
            });
            return marker;
        });

        var markerCluster = new MarkerClusterer(map, markers, {
            imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
        });

    }
});
/*


// JS depedency
var GoogleMapsLoader = require('google-maps'); 

// Initialisation
GoogleMapsLoader.KEY = 'AIzaSyDIJ4bWqu1203zVRNRriw5MzysxYhvr0D0';

GoogleMapsLoader.load(function(google) {

    gmap_markers = function() {

        function gmap_markers_init(){
            var map;
            var mapLat = 16.328490;
            var mapLng = -61.693795;
            var mapZoom = 14;
            var myLatLng = {lat: mapLat, lng: mapLng};

            map = new google.maps.Map(document.getElementById("gmap-simple"), {
                mapTypeId: 'roadmap',
                scrollwheel: false,
                panControl: false,
                zoomControl: true,
                zoom: mapZoom,
                center: myLatLng,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false
            });

         var marker = new google.maps.Marker({
              position: myLatLng,
              map: map,
              title: 'Hello World!',
              icon: '/theme/images/icons/gmap-marker.png'
            });
        }

        gmap_markers_init();
    }

    gmap_cluster = function() {

        var markerCluster = require('js-marker-clusterer');

        var map = new google.maps.Map(document.getElementById('gmap-cluster'), {
            zoom: 12,
            scrollwheel: false,
            panControl: false,
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            center: {
              lat: 14.62,
              lng: -61
            }

        });

        var infoWin = new google.maps.InfoWindow();
        var markers = locations.map(function(location, i) {
            var marker = new google.maps.Marker({
                position: location,
                icon: '/theme/images/icons/gmap-marker.png'
            });
            google.maps.event.addListener(marker, 'click', function(evt) {
                infoWin.setContent(location.info);
                infoWin.open(map, marker);
            });
            return marker;
        });

        var markerCluster = new MarkerClusterer(map, markers, {
            imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
        });
    }

   //gmap_markers();
    gmap_cluster();

});
*/