// JS Dependency
var parsleyjs = require('parsleyjs');
if($("html").attr('lang').slice(0,2) == 'fr'){
	var parsleyjsFR = require('parsleyjs/dist/i18n/fr.js');
}

// Initialisation
$('.parsley-validate').parsley();


if ($('#validation_info_compte').length) {
    $('#motdepasse').blur(function()
    {
        if( $(this).val() ) {
            $('#validation_info_compte input').attr("required", "required");
        } else {
            $('#validation_info_compte input').removeAttr("required");
		}
    });
}




